import React, { FC } from "react";
import SemipolarLoading from "react-loadingg/lib/SemipolarLoading";

const Loading: FC = () => {
	return (
		<SemipolarLoading color="#ffffff" speed={1.5} size="large" style={{}} />
	);
};

export default Loading;
