/* 402efc82ec3234228e3ade0a1a95511ef1901632
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'login'>
);

export type LoginSubSubscriptionVariables = Types.Exact<{
  listen: Types.Scalars['String'];
}>;


export type LoginSubSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Types.Subscription, 'qrLogin'>
);


export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password})
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginSubDocument = gql`
    subscription loginSub($listen: String!) {
  qrLogin(listen: $listen)
}
    `;

/**
 * __useLoginSubSubscription__
 *
 * To run a query within a React component, call `useLoginSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLoginSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginSubSubscription({
 *   variables: {
 *      listen: // value for 'listen'
 *   },
 * });
 */
export function useLoginSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<LoginSubSubscription, LoginSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LoginSubSubscription, LoginSubSubscriptionVariables>(LoginSubDocument, options);
      }
export type LoginSubSubscriptionHookResult = ReturnType<typeof useLoginSubSubscription>;
export type LoginSubSubscriptionResult = Apollo.SubscriptionResult<LoginSubSubscription>;