import React, { FC } from "react";
import { Field } from "formik";
import styles from "./field.module.scss";

type FieldProps = {
	name: string;
	placeholder: string;
	type: string;
	errors?: string;
	touched?: boolean;
};

const MyField: FC<FieldProps> = ({
	name,
	placeholder,
	type,
	errors,
	touched,
}) => (
	<>
		<label className={styles.label} htmlFor={name}>
			{placeholder}
		</label>
		<div className={styles.field}>
			<Field id={name} name={name} placeholder={placeholder} type={type} />
		</div>
		{errors && touched ? <div className={styles.error}>{errors}</div> : null}
	</>
);

export default MyField;
