import React, { FC } from "react";
import styles from "./submit.module.scss";

type SubmitProps = {
	isValid: boolean;
};

const Submit: FC<SubmitProps> = ({ children, isValid }) => (
	<button type="submit" className={isValid ? styles.submit : styles.submitError}>
		{children}
	</button>
);

export default Submit;
