import React, { FC } from "react";
import { Loading } from ".";
import styles from "./loading-center.module.scss";

const LoadingCenter: FC = () => (
	<div className={styles.center}>
		<Loading />
	</div>
);

export default LoadingCenter;
