import type { GetStaticProps, NextPage } from "next";
import React, { useState, useEffect } from "react";
import { Formik, Form, FormikErrors } from "formik";
import { useRouter } from "next/router";
import isEmail from "validator/lib/isEmail";
import type { loginInput } from "../types/login";
import {
	useLoginMutation,
	useLoginSubSubscription,
} from "../lib/graphql/login.graphql";
import { LoadingCenter } from "../components/loading";
import { GetAuth, SetAuth } from "../lib/auth";
import toast from "react-hot-toast";
import { generate as randomstring } from "randomstring";
import QRCode from "react-qr-code";
import {
	Center,
	ContainerWhite,
	WhiteBox,
	FlexCenter,
	CenterWithLogo,
	QrPadding,
	WhiteLink,
	SplitLine,
} from "../components/basic";
import { Field, Submit } from "../components/form";
import { Pages } from "../lib/pages";
import { getTranslations } from "../lib/translations";
import { useTranslations } from "next-intl";

const Login: NextPage = () => {
	const router = useRouter();
	const [loginMutation] = useLoginMutation();
	const [qrLogin] = useState(randomstring(25));
	const [initialValues, setInitialValues] = useState<loginInput>({
		email: "",
		password: "",
	});
	const t = useTranslations();

	const { data, error } = useLoginSubSubscription({
		variables: {
			listen: qrLogin,
		},
	});
	const [loaded, setLoaded] = useState(false);

	const validate = (values: loginInput) => {
		const errors: FormikErrors<loginInput> = {};
		if (!isEmail(values.email)) errors.email = t("wrong-email-format");
		if (!values.password) errors.password = t("password-is-required");
		return errors;
	};

	const submit = async (values: loginInput) => {
		setInitialValues(values);
		try {
			setLoaded(false);
			const { data } = await loginMutation({
				variables: {
					email: values.email,
					password: values.password,
				},
			});
			SetAuth("Bearer " + data.login);
			router.reload();
		} catch (error) {
			setLoaded(true);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		if (GetAuth()) router.push(Pages.Index);
		else setLoaded(true);
	}, [router]);

	useEffect(() => {
		if (data) {
			SetAuth("Bearer " + data.qrLogin);
			router.reload();
		}
	}, [data, router]);

	// if (error)
	// 	return (
	// 		<ContainerWhite>
	// 			<Center>
	// 				<p>{error.message}</p>
	// 			</Center>
	// 		</ContainerWhite>
	// 	);
	if (!loaded)
		return (
			<ContainerWhite>
				<LoadingCenter />
			</ContainerWhite>
		);

	return (
		<ContainerWhite>
			<CenterWithLogo>
				<WhiteBox>
					<Formik
						initialValues={initialValues}
						onSubmit={submit}
						validate={validate}>
						{({ errors, touched, isValid }) => (
							<Form>
								<FlexCenter>
									<Field
										name="email"
										placeholder={t("email")}
										type="email"
										errors={errors.email}
										touched={touched.email}
									/>
									<Field
										name="password"
										placeholder={t("password")}
										type="password"
										errors={errors.password}
										touched={touched.password}
									/>
									<Submit isValid={isValid}>{t("login")}</Submit>
								</FlexCenter>
							</Form>
						)}
					</Formik>
					<SplitLine />
					{!error && (
						<QrPadding>
							<QRCode value={qrLogin} size={200} />
						</QrPadding>
					)}
					<WhiteLink href={Pages.Register}>{t("register")}</WhiteLink>
					<WhiteLink href={Pages.SendEmail}>{t("forgot-password")}</WhiteLink>
				</WhiteBox>
			</CenterWithLogo>
		</ContainerWhite>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const messages = await getTranslations(locale, ["login", "common"]);
	return {
		props: {
			messages,
		},
	};
};

export default Login;
